<template>
  <div class="my">
    <h1 class="page-title">
      我的购买
      <van-icon class="tip" name="info-o" @click="showTip" />
    </h1>

    <div class="card-container">
      <loading v-if="loading" height="60vh" />
      <van-empty v-else-if="!orders.length" description="暂无购买记录！" />
      <template v-else>
        <van-card
          class="card"
          v-for="order in orders"
          :key="order.id"
          :num="order.orderDetail.quantity"
          :price="order.amounts.toFixed(2)"
          :title="order.orderDetail.goodsName"
          :desc="order.createdTime"
        >
          <template #tags>
            <van-tag type="success" style="margin-right: 10px">
              {{ order.statusName }}
            </van-tag>
            <van-tag plain type="primary">
              {{ order.deliveryStatusName }}
            </van-tag>
          </template>
          <template v-if="order.status === 1" #bottom>
            <van-notice-bar
              left-icon="info-o"
              mode="link"
              style="margin-bottom: 8px"
              :scrollable="false"
              @click="showOrderTip"
            >
              {{ orderTip }}
            </van-notice-bar>
          </template>
          <template #footer>
            <span v-if="order.deliveryNumber" style="color: #888">
              物流单号：{{ order.deliveryNumber }}
            </span>
            <van-button
              plain
              hairline
              round
              size="mini"
              type="info"
              icon="contact"
              @click="showAccountInfoDetail(order.accountInfo)"
            >
              账号信息
            </van-button>
          </template>
        </van-card>
      </template>
    </div>
    <van-dialog
      v-model="showDetail"
      confirm-button-color="#2387f5"
      confirm-button-text="我知道了"
    >
      <div class="attr">
        <div class="row">
          <div class="name">爱听说账号：</div>
          <div class="value">{{ accountInfo.account }}</div>
        </div>
        <div class="row">
          <div class="name">学生姓名：</div>
          <div class="value">{{ accountInfo.name }}</div>
        </div>
        <div class="row">
          <div class="name">所在学校：</div>
          <div class="value">{{ accountInfo.schoolName }}</div>
        </div>
        <div class="row">
          <div class="name">所在年级：</div>
          <div class="value">{{ accountInfo.gradeName }}</div>
        </div>
        <div class="row">
          <div class="name">所在班级：</div>
          <div class="value">{{ accountInfo.className }}</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MyTab',
    data() {
      return {
        showDetail: false,
        accountInfo: {},
        loading: false,
        orderTip:
          '注意：待支付订单将在24小时后自动删除，如您需要继续支付，请前往套餐购买界面重新选购。',
      };
    },
    computed: {
      orders() {
        return this.fetchedData.orders || [];
      },
    },
    created() {
      this.$fetchData({
        orderTip: this.$request('comboBuy/fetchSetting', {
          name: 'orderTip',
        }),
      });
    },
    async activated() {
      this.loading = true;
      try {
        await this.$fetchData({
          orders: this.$request('comboBuy/fetchOrders'),
        });
      } finally {
        this.loading = false;
      }
    },
    methods: {
      showTip() {
        this.$dialog.alert({
          title: '温馨提示',
          message: this.fetchedData.orderTip,
        });
      },
      showOrderTip() {
        this.$dialog.alert({
          title: '温馨提示',
          message: this.orderTip,
        });
      },
      showAccountInfoDetail(accountInfo) {
        try {
          this.accountInfo = JSON.parse(accountInfo);
          this.showDetail = true;
        } catch {
          this.$toast({
            message: '无账号信息',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .my {
    padding-bottom: 100px;
    overflow: auto;

    .attr {
      padding: 12px;
      font-size: 14px;
      line-height: 28px;
      .row {
        display: flex;

        .name {
          flex: 0 0 100px;
          text-align: right;
          color: #323233;
        }

        .value {
          flex: 1;
          color: #888888;
        }
      }
    }

    .tip {
      margin-left: 5px;
      font-size: 18px !important;
    }
    .card {
      .van-notice-bar {
        border-radius: 6px;
        height: 24px;
        font-size: 10px;
        line-height: 24px;
        padding: 0 6px;

        ::v-deep {
          .van-notice-bar__left-icon,
          .van-notice-bar__right-icon {
            min-width: 18px;
            font-size: 14px;
          }
        }
      }

      .van-card__title {
        font-size: 16px;
        line-height: 32px;
        color: #555;
      }

      .van-card__price-integer {
        font-size: 20px;
      }

      .van-card__bottom {
        line-height: 40px;
      }

      .van-card__price {
        color: #f00;

        .van-card__price-currency {
          padding-right: 4px;
        }
      }
    }
  }
</style>
